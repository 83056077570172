.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: $white!important;
    background-color: $base;
    border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .nav-link {
    &:hover{
        background: $primary;
        color: $white;
    }
}