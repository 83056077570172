.ih-item.square {
    position: relative;
    width: 100%;
    height: auto;
    border: none;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
 }
 .ih-item.square.effect13 .info h3 {
    background: $base;
}

.ih-item.square.effect12 .info {
    background: rgba($base , 60%);
}
.ih-item.square.effect12 .info h3 {
    background:$primary;
}

.ih-item.square.effect12 .info p {
    font-style: italic;
    font-size: 18px;
    position: relative;
    color: $white;
    padding: 20px 20px 20px;
    text-align: center;
    transition: all 0.35s ease-in;
}