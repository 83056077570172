#list_serv {
    ul:first-child li:before {
        font-family:"Font Awesome 5 Free";
        content: "\e52f";
        font-weight: bold;
        color: $base;
        margin-right:8px;
    }
}
.ih-item.square.effect8.colored .info {
    background: $primary;
}
.ih-item.square.effect3 .info {
    height: auto;
    background: #333;
    opacity: 0;
    transition: all 0.35s ease-in-out;
}