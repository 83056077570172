.redes {
    position: fixed;
    bottom: 50%;
    right: 0%;
    box-shadow: 0 0 5px $black;
    z-index: 12345;
}

.border_white {
    border-bottom: 1px solid rgba($white, $alpha: .3);
}

.bg_face {
    width: 50px;
    float: none;
    background: $face;

    &:hover {
        transform: scale(1.1);
        box-shadow: 0 0 5px $black;
    }
}

.bg_instagram {
    width: 50px;
    float: none;
    background: $instagram;

    &:hover {
        transform: scale(1.1);
        box-shadow: 0 0 5px $black;
    }
}

.bg_twitter {
    width: 50px;
    float: none;
    background: $twitter;

    &:hover {
        transform: scale(1.1);
        box-shadow: 0 0 5px $black;
    }
}