.nav-link {
    color:$white;
    &:hover{
        color:$base
        
    }
}
.bandera{
    max-width: 25px;
}
.bandera_idioma{
    max-width: 60px;
}