.img_casino_game {
    border-radius: 10px;
    border: 2px outset lighten($primary, 30);
    box-shadow: 0 0 10px $primary;
    max-width: 100%;
    transition: all 0.5s ease;

    &:hover {
        transform: scale(1.1);
        z-index: 1234;
        box-shadow: 0 10px 10px 5px $primary;

    }
}