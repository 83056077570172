#span_bokkie_software {
    .bg_base {
        transition: all .001s linear;
        &:hover {
            background: darken($base, 10);
            box-shadow: 0 0 10px $black;
            z-index: 1234;
        }

    }
    .bg_primary {
        transition: all 0.5s linear;
        &:hover {
            background: lighten($primary, 10);
            box-shadow: 0 0 10px $black;
            z-index: 1234;
        }
    }
}

.link_span{
    background: $primary;
    h5{
        color: $white;
    }
    &:hover{
        background:lighten($primary, 20) ;
    }
    i{
        float: right;
    }
}
#span_bokkie_software{
    .text_bg_tr{
        background-color: rgba($primary, .5);
    }
}
.border_bottom{
    border-bottom: 1px solid lighten($primary,15);
}
.border_top{
    border-top: 1px solid darken($primary,25);
}