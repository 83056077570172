@font-face {
    font-family: especial;
    src: url('../../fonts/RemachineScript_Personal_Use.ttf');
}

.font_especial {
    font-family: especial;
}

.font_bold{
    font-weight: bold;
}

.font_shadow{
    text-shadow: 0 0 5px $black;
}

h1,h2,h3,h4,h5{
    color: $primary;
    font-weight: bold;
}
.font_base{
    color: $base;
}
.font_dark_blue{
    color: $primary;
}