.slider{
    min-height: 450px;
    @include image-back;
}
.slider1{
    background-image: url('../../images/slider_home/slider-1.webp');
}
.slider2{
    background-image: url('../../images/slider_home/slider-2.webp');
}
.slider3{
    background-image: url('../../images/slider_home/slider-3.webp');
}
//paginas internas
.slider_about_us{
    background-image: url('../../images/slider_pag/about_us.jpg');
}
.slider_bokkie_software{
    background-image: url('../../images/slider_pag/bookie_software.jpg');
}
.slider_software_features{
    background-image: url('../../images//slider_pag/software_features_1.jpg');
}

.slider_products{
    background-image: url('../../images/slider_pag/products.jpg');
}
.slider_promotions{
    background-image: url('../../images/slider_pag/promotions.jpg');
}
.slider_casino{
    background-image: url('../../images/slider_pag/casino.jpg');
}
// 3s
.fa{
    color: $black!important;
}
.react-3d-carousel .slider-container .slider-right div {
    border-top: 2px solid $primary!important;
    border-right: 2px solid $primary!important;
    border-bottom: 2px solid $primary!important;
    border-left: 2px solid $primary!important;
}
.react-3d-carousel .slider-container .slider-left div {
    border-top: 2px solid $primary!important;
    border-right: 2px solid $primary!important;
    border-bottom: 2px solid $primary!important;
    border-left: 2px solid $primary!important;
}
.BrainhubCarousel__arrows {
    background-color: $primary!important;
}
//products
.skin_img{
    max-height: 300px;
}
.user_img{
    max-height: 400px;
}
//slider casinos header
