.services_span{
    min-height:300px;
    max-width: 400px;
}
.span_sportsbook,.span_live_casino,.span_online_casino,.span_live_betting,.span_live_casino_eng,.span_live_betting_eng{
    background-image: url('../../images/servicios/SPORTSBOOK-SOFTWARE.jpg');
    @include image-back;
    transition: all 0.1s linear;
    &:hover{
        transform: scale(1.03);
        box-shadow: 0 0 5px $black;
    }
}
.span_live_casino{
    background-image: url('../../images/servicios/live-casino.jpg');
}
.span_online_casino{
    background-image: url('../../images/servicios/online-casino.jpg');
}
.span_live_betting{
    background-image: url('../../images/servicios/live-betting.jpg');
}

.span_live_casino_eng{
    background-image: url('../../images/servicios/live-casino-eng.jpg');
}
.span_live_betting_eng{
    background-image: url('../../images/servicios/live-betting-eng.jpg');
}
