.btn_base {
    background-color: $base;
    color: $white;

    &:hover {
        background-color: darken($base, 20);
        color: $white!important;
    }
}

.btn_blanco {
    background-color: $white;
    color: $primary!important;

    &:hover {
        background-color: darken($base, 20);
        color: $white;
    }
}

.btn_new_agent,
.btn_new_agent_movil {
    position: absolute;
    top: 0;
    right: 30px;
    background: $primary;
    color: $white;
    padding: .3rem;
    border-radius: 0 0 5px 5px;
    text-decoration: none !important;

    &:hover {
        color: white !important;
        background: lighten($primary, 20);
    }
}

.btn_new_agent_movil {
    border-radius: 5px;
}