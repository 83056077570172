.bg_base {
    background-color: $base;
}

.bg_primary {
    background: $primary;
}
.bg_black{
    background: $black;
}
.shadow_span {
    box-shadow: 0 0 15px lighten($black, 10);
}

.hr_celeste {
    border: solid 1px $primary;
    opacity: 1;
}
.span_home{
    @include image-back;
    background-image: url('../images/general/cta-1.jpg');
    min-height: 400px;

}
.cta_about{
    @include image-back;
    background-image: url('../images/general/cta_about_us.jpg');
    min-height: 400px;

}

.border_right{
    border-right: 1px solid $primary;
}
//contact
.bg_span_contact{
    @include image-back;
    background-image: url('../images/general/contact_aside.jpg');
}
//loader
.loader {
    width: 100%;
    height: 100vh;
    background-image: url('../images/general/loader.gif');
    background-size: 15%;
    background-repeat: no-repeat;
    background-position: center;
  }
